/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { Box, Button, Grid, Flex, Input, FormControl, Textarea } from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { SubmitAdviceWrapperStyles } from './SubmitPost';

export const AddFact = ({ comedian }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const [source, setSource] = useState('');

  const serviceId = process.env.GATSBY_EMAILJS_SERVICE_ID;
  const templateId = process.env.GATSBY_EMAILJS_TEMPLATE_ID;
  const userId = process.env.GATSBY_EMAILJS_USER_ID;

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, e.target, userId);
    setTimeout(() => {
        setLoading(false);
        setContent('');
        setSource('');
        setSuccess(true);
    }, 1000);
  }

  return (
    <Grid gridTemplateColumns="1fr">
      {success && (
        <SubmitAdviceWrapperStyles>
          <Box mt="-5px" lineHeight="1.25" fontWeight="500" color="#4056a1">Thanks for the submission.  We'll try to add it as soon as possible.</Box>
        </SubmitAdviceWrapperStyles>
      )}
      {!success && (
        <SubmitAdviceWrapperStyles>
          <form onSubmit={sendEmail}>
              <fieldset disabled={loading} style={{ border: 'none' }}>
                <FormControl id="header" style={{ display: 'none'}}>
                  <Input type="text" name="header" value="Add fact for comedian" />
                </FormControl>
                <FormControl id="comedian" style={{ display: 'none'}}>
                  <Input type="text" name="comedian" value={comedian} />
                </FormControl>
                <FormControl id="content">
                  <Textarea placeholder="fact" autoComplete="off" name="content" required value={content} onChange={e => setContent(e.target.value)} />
                </FormControl>
                <FormControl id="source" mt={2}>
                  <Input type="text" placeholder="source" autoComplete="off" name="source" value={source} onChange={e => setSource(e.target.value)} />
                </FormControl>
                <Flex mt={6}>
                  <Button
                    padding="18px 15px"
                    fontSize="15px"
                    type="submit"
                    colorScheme="facebook"
                    isLoading={loading}
                  >
                  Submit
                  </Button>
                </Flex>
              </fieldset>
          </form>
        </SubmitAdviceWrapperStyles>
      )}
    </Grid>
  );
};
