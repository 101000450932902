/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { PageStyles } from '.';
import SEO from '../components/SEO';
import { AddFact } from '../components/AddFact';
import { GenericHeader } from '../components/GenericHeader';
import { CreatePostStyles } from './create-post';
import { DesktopWrapper } from '../templates/Post';

export default function AddFactPage({ location }) {
  const comedian = location
    ? location.search.slice(3).split('-').join(' ')
    : null;
  const createPost = comedian ? `Add fact for ${comedian}` : 'Add fact';
  const comedianName = comedian
    ? comedian
        .split(' ')
        .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
        .join(' ')
    : '';
  return (
    <>
      <SEO title="Create post | Yuks" />
      <PageStyles>
        <CreatePostStyles>
          <DesktopWrapper>
            <GenericHeader header={createPost} />
          </DesktopWrapper>
          <Box className="content-wrapper" marginBottom="10px">
            <h1 style={{ fontSize: '20px', fontWeight: '700' }}>
              Add a fact{' '}
              {comedian ? (
                <span id="comedian-name">for {comedianName}</span>
              ) : (
                ''
              )}
            </h1>
          </Box>
          <AddFact comedian={comedian} />
        </CreatePostStyles>
      </PageStyles>
    </>
  );
}
